.layout {
  @apply flex w-full overflow-x-hidden bg-slate-100;
}

/*SIDEBAR*/
.sidebar__wrapper,
.sidebar__logo,
.sidebar__logo img, 
.sidebar__menu,
.sidebar__menu span:nth-child(1), 
.sidebar__menu span:nth-child(2){
  @apply transition-all duration-200;
}
.sidebar__wrapper {
  @apply fixed left-0 bottom-0 top-0 z-40 -translate-x-[130%] flex lg:overflow-x-hidden  lg:-translate-x-0   lg:max-w-[256px] ;
}
.sidebar__wrapper[aria-label="true"] {
  @apply -translate-x-0 lg:shadow-lg lg:max-w-[5rem] lg:hover:max-w-[256px];
}
.sidebar {
  @apply z-40 flex-shrink-0 h-screen bg-white border-r shadow-lg w-52;
}
.sidebar__logo {
  @apply relative flex items-center h-16 px-5 mb-8 overflow-x-hidden;
}
.sidebar__logo img {
  @apply absolute top-0 h-16 py-1 left-4;
}
/* .sidebar__wrapper[aria-label="true"] .sidebar__logo img:nth-child(1) {
  @apply lg:opacity-0;
}
.sidebar__wrapper[aria-label="true"]:hover .sidebar__logo img:nth-child(1) {
  @apply lg:opacity-100;
} */
.sidebar__menus {
  @apply flex flex-col justify-between w-full space-y-1 text-sm;
}
.sidebar__menu {
  @apply flex items-center w-full px-5 py-2 cursor-pointer lg:hover:bg-pari-blue-100;
}
.sidebar__menu[aria-label="active"] {
  @apply bg-pari-blue-50 lg:hover:bg-pari-blue-100 text-pari-blue-500;
}
.sidebar__menu span:nth-child(1){
  @apply max-w-[2.25rem] flex justify-center w-full;
}
.sidebar__menu span:nth-child(2){
  @apply font-semibold capitalize lg:max-w-full lg:overflow-hidden;
}

.sidebar__wrapper[aria-label="true"] .sidebar__menu span:nth-child(2){
  @apply lg:max-w-0;
}
.sidebar__wrapper[aria-label="true"]:hover .sidebar__menu span:nth-child(2){
  @apply lg:max-w-full;
}
.sidebar__close {
  @apply flex items-center justify-center w-8 h-8 mt-4 ml-4 bg-white rounded-full cursor-pointer lg:hidden absolute left-[13rem] top-0 z-50;
}

/*CONTENT*/
.content__wrapper {
  @apply flex flex-col w-full h-screen;
  transition: margin-left 0.2s;
}
.sidebar__wrapper[aria-label="true"] ~ .content__wrapper {
  @apply lg:ml-[5rem];
}
.content {
  @apply flex flex-col md:px-5;
}
.content__title__wrapper {
  @apply flex items-center justify-between col-span-12 px-5 py-5 md:px-0 lg:flex-row lg:items-center lg:space-y-0 sm:flex-row 
}
.content__title {
  @apply text-lg font-semibold;
}
.content__actions {
  @apply flex items-center text-sm lg:ml-4;
}

/*HEADER*/
.header {
  @apply flex items-center justify-between flex-shrink-0 w-full h-16 px-2 text-white bg-pari-blue;
}
.header__menus {
  @apply flex items-center;
}
.header__menu {
  @apply flex items-center justify-center h-12 px-5 transition duration-200 rounded-md;
}
.header__menu--icon {
  @apply text-2xl;
}
.header__menu--account {
  @apply rounded-md cursor-pointer lg:hover:bg-pari-blue-600;
}
.header__menu--account .account__username {
  @apply hidden mr-2 text-sm font-medium md:block;
}
.header__menu--account .account__image {
  @apply flex items-center justify-center w-8 h-8 overflow-hidden text-sm rounded-full bg-pari-orange ;
}
.header__notification {
  @apply relative;
}
.header__notification .notification__dot{
  @apply absolute top-0 right-0 w-2 h-2 rounded-full bg-pari-orange-500;
}
.dropdown__card{
  @apply absolute top-14 right-5 bg-white divide-y divide-gray-100 rounded-lg shadow-xl w-44 dark:bg-gray-700 dark:divide-gray-600 z-50
}


