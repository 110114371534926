.auth-layout {
  @apply relative w-full min-h-screen;
  background: linear-gradient(320.15deg, #00549E 20.54%, #0063AA 35.57%, #0071B5 55.6%, #0080BF 76.99%, #008FC9 94.29%);
}
.auth-layout__logo {
  @apply top-0 left-0 flex justify-start py-3 pl-5 pr-10 bg-white rounded-br-full md:fixed w-44;
}
.auth-layout__logo img {
  @apply h-8 ;
}
.auth-layout__form {
  @apply flex flex-col items-center min-h-full w-full max-w-md px-8 py-16 md:mt-0 mx-auto text-pari-black bg-white text-sm md:justify-center md:py-[20vh] 2xl:py-[15vh] relative z-20;
}
.auth-layout__title {
  @apply text-lg text-left md:text-xl lg:text-2xl;
}
.auth-layout__subtitle {
  @apply mb-6 text-sm opacity-80 md:text-base;
}
.auth-layout__inputs {
  @apply relative flex flex-col w-full mb-2 space-y-3 text-pari-black ;
}
.auth-layout__inputs .input__label {
  @apply text-pari-black
}
.auth-layout__shape {
  @apply absolute bottom-0 left-0 right-0 z-0 flex-shrink-0 object-cover object-left h-64 md:h-auto md:w-full;
}