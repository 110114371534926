@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: avenirNextRegular;
  src: url('../public/assets/fonts/AvenirNextLTPro-Regular.otf');
}
@font-face {
  font-family: avenirNextMedium;
  src: url('../public/assets/fonts/AvenirNextLTPro-Medium.otf');
}
@font-face {
  font-family: avenirNextDemi;
  src: url('../public/assets/fonts/AvenirNextLTPro-Demi.otf');
}
@font-face {
  font-family: avenirNextBold;
  src: url('../public/assets/fonts/AvenirNextLTPro-Bold.otf');
}
@font-face {
  font-family: avenirNextHeavy;
  src: url('../public/assets/fonts/AvenirNextLTPro-Heavy.otf');
}

@layer base {
  body {
    @apply text-pari-black;
    font-family: 'Inter', sans-serif;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type=number] {
    -moz-appearance: textfield;
  }
}

@layer components {
  .link-text {
    @apply transition duration-200;
  }
  .link-text--to-primary {
    @apply hover:text-pari-blue-500;;
  }
  .link-text--to-secondary {
    @apply hover:text-pari-orange-500;;
  }
}

@layer utilities {
  .white-wrapper {
    @apply p-5 bg-white md:rounded-lg;
  }
  .white-wrapper--none {
    @apply block p-0 bg-transparent rounded-none;
  }
  .full-grid {
    @apply grid w-full grid-cols-12 col-span-full;
  }
}