.table__wrapper {
  @apply overflow-x-hidden rounded-t-lg text-sm  border-b border-t;
}
.table, 
.table tr,
.table tbody {
  @apply w-full text-center;
}
.table thead {
  @apply text-white bg-pari-blue-500;
}
.table thead th{
  @apply font-medium;
}
.table tbody tr:nth-child(odd) {
  @apply bg-gray-50 xl:hover:bg-pari-blue-50 transition duration-150;
}
.table thead th, .table tbody td {
  @apply px-3 py-2;
}
.table__head__row {
  @apply items-center justify-between hidden xl:flex col-span-full;
}
.table .row__number {
  @apply flex-shrink-0 w-10;
}
.table__data__icon {
  @apply flex justify-center col-span-2;
}

@screen xl {
  .table__row {
    @apply flex items-center justify-between col-span-full border-b;
  }
}